@import 'tailwind/_base.css';
@import 'tailwind/_components.css';
@import 'tailwind/_utilities.css';

@import 'tea.css';

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  @apply bg-black text-slate-100;
  overscroll-behavior: none;
}

@keyframes blinking-cursor {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
