@import '@workspace/ui/src/styles/main.css';
@import 'animate.css';

body {
  @apply bg-[#e5e5e5] text-white;

  overscroll-behavior: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.main-layout {
  min-height: 100vh;
  min-height: 100dvh;
}
